import { hc } from "hono/client";
import useTheme from "../../../js/composables/useTheme";
import { AppType } from "./index";

const baseUrl = useTheme().IS_STAGING
  ? "https://dutch-assistant-staging.dutchpet.workers.dev"
  : "https://dutch-assistant.dutchpet.workers.dev";

export const DutchAssistantWorker = hc<AppType>(baseUrl, {
  init: {
    // @ts-expect-error
    credentials: "include",
  },
});
